import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import { PageOnSiteQuery } from '@generated/GraphqlTypes';
import Base from '@webLayouts/Base/Base';
import Default from '@webPages/ModularHomes/Default/Default';

const IndexPage: React.FC<PageProps<PageOnSiteQuery>> = ({ data }) => {
  const title = data?.onSite?.frontmatter?.title || '';
  const seoTitle = data?.onSite?.frontmatter?.seoTitle || title || undefined;
  const seoDescription =
    data?.onSite?.frontmatter?.seoDescription ||
    data?.onSite?.excerpt ||
    undefined;

  const image = data?.onSite?.frontmatter?.heroImage?.publicURL || undefined;

  const sections = (data?.onSite?.frontmatter?.sections || []).map(
    (column: any) => ({
      title: column?.title || '',
      body: column?.body || '',
    })
  );

  const onSite = {
    title,
    body: data?.onSite?.html || '',
    heroImage: getImage(
      data?.onSite?.frontmatter?.heroImage as ImageDataLike
    ) as IGatsbyImageData,
    sections,
  };

  return (
    <Base
      theme="light"
      title={seoTitle}
      description={seoDescription}
      image={image}
    >
      <Default {...onSite} />
    </Base>
  );
};

export const query = graphql`
  query PageOnSite {
    onSite: markdownRemark(
      fields: { collection: { eq: "pages" } }
      frontmatter: { page: { eq: "on-site" } }
    ) {
      html
      excerpt
      frontmatter {
        title

        seoTitle
        seoDescription

        heroImage {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 2000
              # height: 650
              # placeholder: BLURRED
              # quality: 70
              # formats: [AUTO, WEBP, AVIF, JPG]
            )
          }
        }

        sections {
          title
          body
        }
      }
    }
  }
`;

export default IndexPage;
